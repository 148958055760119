import request from '@/utils/request'

// 查询知识库管理员
export function getAdmin(params) {
  return request({
    url: '/admin/getAdmin',
    method: 'get',
    params: params
  })
}
// 查询知识库所有子管理员
export function getSubAdmin(params) {
  return request({
    url: '/admin/getSubAdmin',
    method: 'get',
    params: params
  })
}
// 新增知识库管理员
export function addAdmin(params) {
  return request({
    url: '/admin/addAdmin',
    method: 'post',
    data: params
  })
}
// 新增知识空间
export function addKnowledgeSpace(params) {
  return request({
    url: '/knowledge/addKnowledgeSpace',
    method: 'post',
    data: params
  })
}
// 设置知识空间基本信息（除成员管理）
export function updateKnowledgeSpace(params) {
  return request({
    url: '/knowledge/updateKnowledgeSpace',
    method: 'post',
    data: params
  })
}
// 查询知识库
export function getKnowledgeSpace(params) {
  return request({
    url: '/knowledge/getKnowledgeSpace',
    method: 'get',
    params: params
  })
}
// 查询知识空间设置--权限设置--成员
export function getKnowledgeSpacePeople(params) {
  return request({
    url: '/knowledge/getKnowledgeSpacePeople',
    method: 'get',
    params: params
  })
}
// 删除知识空间--软删
export function deleteKnowledgeSpace(params) {
  return request({
    url: '/knowledge/deleteKnowledgeSpace',
    method: 'post',
    data: params
  })
}
// 查询可编辑的知识库
export function getEditKnowledgeSpace(params) {
  return request({
    url: '/knowledge/getEditKnowledgeList',
    method: 'get',
    params: params
  })
}
