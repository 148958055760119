<template>
  <div>
    <el-dialog title="知识空间设置" :visible.sync="dialogVisible" :width="fullScreen?'100%':'880px'" :fullscreen="fullScreen" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
      <template slot="title">
        <span class="el-dialog__title">知识空间设置</span>
        <i :class="['dialog-screen',fullScreen?'el-icon-rank':'el-icon-full-screen']" :title="fullScreen?'还原':'全屏'" @click="changeScreen"></i>
      </template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="1"></el-tab-pane>
        <el-tab-pane label="权限设置" name="2"></el-tab-pane>
        <el-tab-pane label="安全设置" name="3"></el-tab-pane>
        <el-tab-pane label="更多" v-if="isAdmin" name="4"></el-tab-pane>
      </el-tabs>
      <el-form :model="form" size="small" ref="ruleForm" label-position="top">
        <el-form-item v-if="activeName === '1'" label="名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="简介" :label-width="formLabelWidth" prop="remark">
          <el-input type="textarea" :rows="2" v-model="form.remark" autocomplete="off" resize="none"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="设置封面">
          <div class="coverConf">
            <div class="previews">
              <img class="previewsBg" :src="activeImg.img" alt="">
              <div class="previewMask"></div>
              <h3 class="title ellipsis-multi" :style="{'color': activeImg.color}">{{ form.name }}</h3>
              <p class="content ellipsis-multi" :style="{'color': activeImg.color}">{{ form.remark }}</p>
            </div>
            <div class="options">
              <div class="types">
                <p v-for="item in typeList" :key="item.type" @click="changeTypes(item)" class="types-item" :class="{'active': item.type === active}">{{ item.label }}</p>
              </div>
              <div class="typesShow">
                <img class="iamges" v-for="item in optionsList" :key="item.no" :src="item.img" :class="{'active': item.no===activeImg.no}" @click="selectImg(item)" alt="">
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" prop="isToAll" class="radio-style">
          <span class="radio-style-span">
            <span>知识空间及其内部文章公开范围</span>
            <el-tooltip :content="content1" placement="top">
              <svg-icon icon-class="exclamation" class="tips"></svg-icon>
            </el-tooltip>
          </span>
          <el-radio-group v-model="form.isToAll">
            <el-radio :label="0">仅知识空间成员可见</el-radio>
            <el-radio :label="1">所有人公开可见（只读）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="activeName === '3'" prop="isCreate" class="radio-style">
          <span class="radio-style-span">谁可以新建空间的一级页面？</span>
          <el-radio-group v-model="form.isCreate">
            <el-radio :label="1">管理员和成员</el-radio>
            <el-radio :label="0">仅管理员</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-if="activeName === '2'" class="authority">
        <el-tabs v-model="activeNamePeople" @tab-click="changePermission">
          <el-tab-pane label="管理员" name="3" v-if="isAdmin"></el-tab-pane>
          <el-tab-pane label="可编辑人员" name="2"></el-tab-pane>
          <el-tab-pane label="可阅读人员" name="1"></el-tab-pane>
        </el-tabs>
        <el-input v-if="activeNamePeople !== '3'" class="search-user" v-model="input" placeholder="请输入内容" size="mini" clearable :maxlength="100" @input="changeInput"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
        <div class="authority-container">
          <div class="authority-container-top">
            <div>默认权限：
              <!-- <el-select v-model="defaultPermission" placeholder="请选择" :disabled="true" @change="changePermission">
                <el-option v-for="item in defaultOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select> -->
              <span>{{ activeNamePeople === '3'?'可管理':(activeNamePeople === '2'?'可编辑':'可阅读') }}</span>
            </div>
            <el-button v-if="activeNamePeople !== '3'" type="primary" icon="el-icon-plus" size="mini" @click="addUser">添加成员</el-button>
          </div>
          <ul v-if="activeNamePeople !== '3'">
            <li v-for="(item, index) in (input ? inputList : activeNamePeople === '2'?list.edit:list.view)" :key="index">
              <img v-if="!item.name" src="../../../../public/static/img/organization.png" alt="">
              <div v-if="item.name" class="img">{{item.name && item.name.length > 2 ? item.name.substring(1, item.name.length) : item.name }}</div>
              <div class="message">
                <span>{{item.name ? item.name : item.udName}}</span>
                <p>{{item.udName}}</p>
              </div>
            </li>
          </ul>
        </div>
        <el-select v-show="activeNamePeople === '3'" v-model="adminList.select" style="width: 100%;margin-top: 15px;" filterable multiple placeholder="请选择当前知识库管理员">
          <el-option
            v-for="(item,index) in $store.state.user.workcodeList"
            :key="index"
            :label="(item.name ? item.name: item.username) + '['+item.workcode+']'"
            :value="item.workcode">
          </el-option>
        </el-select>
      </div>
      <div v-if="activeName === '4' && isAdmin">
        <h3>知识空间管理</h3>
        <el-alert title="删除该知识空间及其所有页面。删除后若要恢复，请联系企业知识库管理员，请谨慎进行。" type="warning" show-icon :closable="false"></el-alert>
        <el-button type="danger" style="margin-top: 20px;" @click="deleteKnowledge">删除知识空间</el-button>
      </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
    </el-dialog>
    <department-tree :visible="visible" :user="activeNamePeople" :list="list[activeNamePeople === '2'?'edit':'view']" @submit="addUserList" @closeTree="closeTree" />
  </div>
</template>
<script>
import departmentTree from '@/components/departmentTree/index.vue'
import { updateKnowledgeSpace, getKnowledgeSpacePeople, deleteKnowledgeSpace } from '@/api/knowledgePermissions'
import { _throttle } from '@/utils/index.js'
import imagesList from '../imageData.js'

export default {
  components: { departmentTree },
  props: {
    isAdmin: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: '100px',
      activeName: '1',
      activeNamePeople: '2',
      visible: false,
      form: {
        name: '',
        remark: '',
        isToAll: 1,
        isCreate: 1
      },
      list: { // 包含编辑和阅读
        edit: [],
        view: []
      },
      // defaultOptions: [
      //   {
      //     label: '可阅读',
      //     value: 1
      //   },
      //   {
      //     label: '可编辑',
      //     value: 2
      //   }
      // ],
      dataList: {},
      oldList: [],
      input: '',
      inputList: [], // 搜索后的list
      adminList: {
        select: [],
        data: []
      }, // 单个知识库管理员
      fullScreen: false,
      content1: `仅知识空间成员可见：需要配置了编辑或阅读权限的人员，才会有知识库的入口\n\n所有人公开可见(只读)：全部人员可以看到该知识库，并可查看其所有文章`,
      // activeSrc: require('@/assets/images/bg01.png')
      activeImg: imagesList[0],
      active: 'all',
      typeList: [
        { label: '全部', type: 'all' },
        { label: '色彩', type: 1 },
        { label: '办公', type: 2 },
        { label: '科技', type: 3 },
        { label: '风景', type: 4 }
      ],
      optionsList: []
    }
  },
  methods: {
    changeTypes(item) {
      this.active = item.type
      this.optionsList = this.active === 'all' ? imagesList : imagesList.filter(ele => ele.type === item.type)
    },
    selectImg(item) {
      this.activeImg = item
    },
    openDialog(item) {
      this.activeName = '1'
      this.dataList = JSON.parse(JSON.stringify(item))
      this.form = item
      this.fullScreen = false
      this.dialogVisible = true
      this.input = ''
      this.activeImg = imagesList.find(ele => ele.no === item.bgNo)
      this.getpeople(item.id)
    },
    getpeople(kbId) {
      getKnowledgeSpacePeople({ kbId: kbId }).then(res => {
        this.list.edit = res.data && res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)).filter(ele => !ele.isAdmin).filter(ele => ele.permission === 2) : []
        this.list.view = res.data && res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)).filter(ele => !ele.isAdmin).filter(ele => ele.permission === 1) : []
        // this.list = res.data && res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)).filter(ele => !ele.isAdmin) : []
        this.oldList = res.data && res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)).filter(ele => !ele.isAdmin) : []
        const arr = (res.data || []).filter(ele => ele.isAdmin).map(item => item.workcode)
        this.adminList.select = []
        this.adminList.select.push(...arr)
        this.adminList.data = res.data && res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)).filter(ele => ele.isAdmin) : []
      }).catch(error => console.log(error))
    },
    handleClose() {
      this.dialogVisible = false
      this.form = this.dataList
      this.list.edit = []
      this.list.view = []
    },
    handleClick() {},
    submit: _throttle(function() {
      const params = {
        ...this.form,
        id: this.dataList.id,
        updateUser: this.$store.getters.name,
        updateCode: this.$store.getters.workcode,
        // permission: this.defaultPermission,
        list: this.list.edit.concat(this.list.view)
      }
      params.bgNo = this.activeImg.no
      if (params.list && params.list.length > 0) {
        params.list.forEach(item => {
          if (typeof item.departmentId === 'string') {
            const arr = item.udId.split('-')
            this.$set(item, 'departmentId', arr[arr.length - 1])
          }
        })
      }
      if (this.adminList.select.length) {
        const arr = []
        if (this.adminList.select.length) {
          this.adminList.select.forEach(item => {
            const index = this.adminList.data.findIndex(ele => ele.workcode === item)
            item = {
              ...this.$store.state.user.workcodeList.find(element => element.workcode === item),
              workcode: item,
              isAdmin: 1
            }
            if (index > -1) {
              arr.splice(index, 1, this.adminList.data[index])
            } else {
              arr.push(item)
            }
          })
        }
        params.list.push(...arr)
      }
      updateKnowledgeSpace(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.handleClose()
          this.$emit('getList')
        } else {
          this.$message.error(res.message)
        }
      }).catch(error => console.log(error))
    }, 3000),
    addUser() {
      this.visible = true
    },
    addUserList(val, flag) {
      if (flag) {
        this.list[this.activeNamePeople === '2'?'edit':'view'] = val
        this.oldList = this.list.edit.concat(this.list.view)
      }
    },
    closeTree(val) {
      this.visible = false
      this.list.edit = this.oldList.filter(ele => ele.permission === 2)
      this.list.view = this.oldList.filter(ele => ele.permission === 1)
    },
    changePermission(val) {
      // const old = val === 1 ? 2 : 1
      // const newTitle = val === 1 ? '可阅读' : '可编辑'
      // const oldTitle = newTitle === '可阅读' ? '可编辑' : '可阅读'
      // this.defaultPermission = old
      // this.$confirm(`更改后，成员在页面内的权限将由"${oldTitle}"变更为"${newTitle}"，确认更改？`, '更改全体成员权限', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.defaultPermission = val
      //   this.$message({
      //     type: 'success',
      //     message: '更改成功! 需点击确定生效'
      //   })
      // }).catch(() => {
      //   this.defaultPermission = old
      //   this.$message({
      //     type: 'info',
      //     message: '更改失败'
      //   })
      // })
      if (this.input) {
        this.changeInput(this.input)
      }
    },
    changeInput(val) {
      const arr = JSON.parse(JSON.stringify(this.list[this.activeNamePeople === '2'?'edit':'view']))
      if (this.input) {
        this.inputList = arr.filter(item => {
          if (item.name) {
            return item.name.indexOf(val) > -1
          } else if (item.udName) {
            return item.udName.indexOf(val) > -1
          }
        })
        if (this.inputList.length === 0) {
          this.inputList = [{ udName: '无搜索结果' }]
        }
      }
    },
    deleteKnowledge() {
      // this.$confirm('该操作无法撤销，立即生效。删除后，空间内所有页面将被同时删除，并通知其他空间管理员。', '删除知识空间', {
      this.$confirm(`该操作无法撤销，并立即生效，请确认是否需要删除空间名：${this.dataList.name}的知识空间？`, '删除知识空间', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteKnowledgeSpace({ id: this.dataList.id }).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.handleClose()
            this.$emit('getList')
          } else {
            this.$message.error(res.message)
          }
        }).catch(error => console.log(error))
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    changeScreen() {
      this.fullScreen = !this.fullScreen
    }
  },
  mounted() {
    if (this.$store.state.user.workcodeList.length === 0) {
      this.$store.dispatch('GetWorkcode')
    }
    this.optionsList = imagesList
  },
}
</script>

<style lang="scss" scoped>
  ul {
    list-style-type: none;
  }
  /deep/.el-dialog {
    .el-dialog__title {
      font-weight: 900;
      font-size: 18px;
    }
    .dialog-screen {
      position: absolute;
      top: 13px;
      right: 50px;
      color: #909399;
      &:hover {
        cursor: pointer;
        color: #5cb6ff;
      }
    }
    .el-form {
      width: 100%;
      .radio-style {
        .el-form-item__content {
          border: 1px solid #1f232926;
          border-radius: 5px;
          .radio-style-span {
            margin-left: 20px;
            height: 40px;
            // display: block;
            display: flex;
            align-items: center;
            .tips {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
          }
          .el-radio {
            display: block;
            margin-left: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .authority {
      width: 100%;
      position: relative;
      .search-user {
        position: absolute;
        top: 5px;
        width: 150px;
        right: 0px;
      }
      .authority-container {
        &-top {
          display: flex;
          justify-content: space-between;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #1f232926;
          padding-bottom: 55px;
          .el-button--primary {
            width: 110px;
            height: 30px;
            margin-top: 5px;
          }
          .el-input__inner {
            width: 95px;
            border: none;
          }
        }
        ul {
          min-height: 68px;
          height: calc(100vh - 600px);
          overflow-y: auto;
          li {
            height: 68px;
            border-bottom: 1px solid #1f232926;
            display: flex;
            .img {
              width: 28px;
              height: 28px;
              background-color: #f4870a;
              border-radius: 50%;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
              margin-top: 18px;
              margin-right: 15px;
            }
            img {
              width: 28px;
              height: 28px;
              margin-top: 18px;
              margin-right: 15px;
            }
            .message {
              display: inline-block;
              padding-top: 15px;
              span {
                font-weight: 700;
              }
              p {
                font-size: 12px;
                margin: 6px 0;
              }
            }
          }
        }
      }
    }
  }
  /deep/.is-fullscreen {
    height: 100%;
    background-color: #ffffff;
    .authority-container ul {
      height: calc(100vh - 400px)!important;
    }
  }
  .coverConf {
    display: flex;
    height: 310px;
    padding: 14px 0;
    margin-top: 10px;
    border: 1px solid rgb(222,224,227);
    border-radius: 6px;
    width: 837px;
    .previews {
      position: relative;
      width: 207px;
      height: 100%;
      margin-left: 14px;
      padding: 35px 22px;
      .previewsBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .previewMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0);
      }
      .title {
        font-weight: 600;
        font-size: 23px;
        line-height: 33px;
        letter-spacing: 1.5px;
        position: relative;
        font-style: normal;
        font-stretch: normal;
        margin: 0;
      }
      .content {
        font-size: 15px;
        line-height: 25px;
        letter-spacing: .8px;
        margin: 4px 0 0 0;
        position: relative;
        font-style: normal;
        font-stretch: normal;
        font-weight: 400;
      }
      .ellipsis-multi {
        display: -webkit-box;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
    }
    .options {
      margin-left: 10px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .types {
        padding-left: 4px;
        height: 24px;
        display: flex;
        &-item {
          padding: 0px 8px;
          margin: 0 8px 0 0;
          border-radius: 6px;
          text-align: center;
          list-style: none;
          cursor: pointer;
          color: rgb(31, 35, 41);
          white-space: nowrap;
          font-size: 12px;
          line-height: 24px;
          &:hover {
            background-color: rgba(31, 35, 41, 0.05);
          }
          &.active {
            background-color: rgba(51, 109, 244, 0.2);
            color: rgb(20, 86, 240);
            font-weight: bold;
          }
        }
      }
      .typesShow {
        width: 600px;
        height: 256px;
        overflow-y: auto;
        padding: 3px 3px 0 4px;
        margin-top: 8px;
        padding-top: 5px;
        .iamges {
          width: 72px;
          height: 98px;
          margin: 0 12px 12px 0;
          border: 1px solid rgb(222, 224, 227);
          cursor: pointer;
          border-radius: 6px;
          &:nth-child(7n+7) {
            margin-right: 0;
          }
          &:hover {
            padding: 1px;
            border: 1px solid rgb(51, 109, 244);
            transform: scale(1.04) translateY(-1px);
            transition: all 0.3s ease-out 0s;
          }
          &.active {
            padding: 1px;
            box-shadow: rgb(20, 86, 240) 0 0 0 2px;
            transform: scale(1);
          }
        }
      }
    }
  }
</style>
