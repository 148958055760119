<template>
  <div v-if="visible">
    <el-dialog :visible="true" width="600px" title="新建到" v-ismove :close-on-click-modal="false" :before-close="closeTab">
      <div style="min-height:400px;padding-bottom:40px;position:relative">
        <el-input placeholder="请输入内容" v-model="filterText" clearable size="small" style="margin-bottom: 10px">
          <template slot="prepend">
            <i class="el-icon-search"></i>
          </template>
        </el-input>
        <el-tree
          class="filter-tree"
          :data="catalogTree"
          :props="defaultProps"
          :default-expand-all="false"
          :filter-node-method="filterNode"
          @node-click="selectNode"
          show-checkbox
          ref="tree">
        </el-tree>
        <div style="text-align:right;position:absolute;bottom:0px;width:100%">
          <el-button size="small" @click="closeTab">取消</el-button>
          <el-button size="small" type="primary" :disabled="!checkedId" @click="forAdd">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filterText: '',
      catalogTree: [
        {
          departId: 1,
          departName: 'IT',
          catalogId: 1,
          catalogName: 'IT部',
          children: [
            {
              departId: 1,
              departName: 'IT',
              catalogId: 11,
              catalogName: 'IT部公共资料',
              children: [
                {
                  departId: 1,
                  departName: 'IT',
                  catalogId: 111,
                  catalogName: '系统使用说明'
                }
              ]
            },
            {
              departId: 1,
              departName: 'IT',
              catalogId: 12,
              catalogName: '技术规范'
            }
          ]
        },
        {
          departId: 2,
          departName: '财务',
          catalogId: 2,
          catalogName: '财务部',
          children: [
            {
              departId: 2,
              departName: '财务',
              catalogId: 21,
              catalogName: '电商组'
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'catalogName'
      },
      checkedId: null,
      catalogName: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    departId: {
      type: Number,
      default: null
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    closeTab() {
      this.$emit('closeTab')
    },
    filterNode(value, data) {
      if (!value) return true
      return data.catalogName.indexOf(value) !== -1
    },
    selectNode(node) {
      console.log(node)
      this.checkedId = node.catalogId
      this.catalogName = node.catalogName
    },
    forAdd() {
      this.$emit('addContentSpaceNode', this.checkedId ? -1 : this.checkedId)
    }
  }
}
</script>
