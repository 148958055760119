export default [
  { img: require('@/assets/images/bg01.png'), color: '#ffffff', no: 1, type: 1 },
  { img: require('@/assets/images/bg02.png'), color: '#ffffff', no: 2, type: 1 },
  { img: require('@/assets/images/bg03.png'), color: '#ffffff', no: 3, type: 1 },
  { img: require('@/assets/images/bg04.png'), color: '#ffffff', no: 4, type: 1 },
  { img: require('@/assets/images/bg05.png'), color: '#ffffff', no: 5, type: 1 },
  { img: require('@/assets/images/bg06.png'), color: '#ffffff', no: 6, type: 1 },
  { img: require('@/assets/images/bg07.png'), color: '#1f2329', no: 7, type: 1 },
  { img: require('@/assets/images/bg08.png'), color: '#ffffff', no: 8, type: 1 },
  { img: require('@/assets/images/bg09.png'), color: '#1f2329', no: 9, type: 1 },
  { img: require('@/assets/images/bg10.png'), color: '#1f2329', no: 10, type: 1 },
  { img: require('@/assets/images/bg11.png'), color: '#1f2329', no: 11, type: 1 },
  { img: require('@/assets/images/bg12.png'), color: '#1f2329', no: 12, type: 1 },
  { img: require('@/assets/images/bg13.png'), color: '#ffffff', no: 13, type: 1 },
  { img: require('@/assets/images/bg14.png'), color: '#1f2329', no: 14, type: 1 },
  { img: require('@/assets/images/bg15.png'), color: '#ffffff', no: 15, type: 1 },
  { img: require('@/assets/images/bg16.png'), color: '#1f2329', no: 16, type: 1 },
  { img: require('@/assets/images/bg17.png'), color: '#1f2329', no: 17, type: 2 },
  { img: require('@/assets/images/bg18.png'), color: '#1f2329', no: 18, type: 2 },
  { img: require('@/assets/images/bg19.png'), color: '#1f2329', no: 19, type: 2 },
  { img: require('@/assets/images/bg20.png'), color: '#1f2329', no: 20, type: 2 },
  { img: require('@/assets/images/bg21.png'), color: '#1f2329', no: 21, type: 2 },
  { img: require('@/assets/images/bg22.png'), color: '#1f2329', no: 22, type: 2 },
  { img: require('@/assets/images/bg23.png'), color: '#1f2329', no: 23, type: 2 },
  { img: require('@/assets/images/bg24.png'), color: '#1f2329', no: 24, type: 2 },
  { img: require('@/assets/images/bg25.png'), color: '#ffffff', no: 25, type: 2 },
  { img: require('@/assets/images/bg26.png'), color: '#1f2329', no: 26, type: 2 },
  { img: require('@/assets/images/bg27.png'), color: '#1f2329', no: 27, type: 2 },
  { img: require('@/assets/images/bg28.png'), color: '#ffffff', no: 28, type: 2 },
  { img: require('@/assets/images/bg29.png'), color: '#1f2329', no: 29, type: 2 },
  { img: require('@/assets/images/bg30.png'), color: '#1f2329', no: 30, type: 2 },
  { img: require('@/assets/images/bg31.png'), color: '#1f2329', no: 31, type: 2 },
  { img: require('@/assets/images/bg32.png'), color: '#1f2329', no: 32, type: 2 },
  { img: require('@/assets/images/bg33.png'), color: '#ffffff', no: 33, type: 2 },
  { img: require('@/assets/images/bg34.png'), color: '#1f2329', no: 34, type: 2 },
  { img: require('@/assets/images/bg35.png'), color: '#1f2329', no: 35, type: 2 },
  { img: require('@/assets/images/bg36.png'), color: '#1f2329', no: 36, type: 2 },
  { img: require('@/assets/images/bg37.png'), color: '#ffffff', no: 37, type: 2 },
  { img: require('@/assets/images/bg38.png'), color: '#ffffff', no: 38, type: 2 },
  { img: require('@/assets/images/bg39.png'), color: '#1f2329', no: 39, type: 2 },
  { img: require('@/assets/images/bg40.png'), color: '#1f2329', no: 40, type: 2 },
  { img: require('@/assets/images/bg41.png'), color: '#1f2329', no: 41, type: 2 },
  { img: require('@/assets/images/bg42.png'), color: '#ffffff', no: 42, type: 2 },
  { img: require('@/assets/images/bg43.png'), color: '#1f2329', no: 43, type: 2 },
  { img: require('@/assets/images/bg44.png'), color: '#1f2329', no: 44, type: 2 },
  { img: require('@/assets/images/bg45.png'), color: '#1f2329', no: 45, type: 2 },
  { img: require('@/assets/images/bg46.png'), color: '#1f2329', no: 46, type: 2 },
  { img: require('@/assets/images/bg47.png'), color: '#1f2329', no: 47, type: 2 },

  { img: require('@/assets/images/bg48.png'), color: '#ffffff', no: 48, type: 3 },
  { img: require('@/assets/images/bg49.png'), color: '#1f2329', no: 49, type: 3 },
  { img: require('@/assets/images/bg50.png'), color: '#ffffff', no: 50, type: 3 },
  { img: require('@/assets/images/bg51.png'), color: '#ffffff', no: 51, type: 3 },
  { img: require('@/assets/images/bg52.png'), color: '#ffffff', no: 52, type: 3 },
  { img: require('@/assets/images/bg53.png'), color: '#ffffff', no: 53, type: 3 },
  { img: require('@/assets/images/bg54.png'), color: '#ffffff', no: 54, type: 3 },
  { img: require('@/assets/images/bg55.png'), color: '#ffffff', no: 55, type: 3 },
  { img: require('@/assets/images/bg56.png'), color: '#ffffff', no: 56, type: 3 },
  { img: require('@/assets/images/bg57.png'), color: '#ffffff', no: 57, type: 3 },
  { img: require('@/assets/images/bg58.png'), color: '#1f2329', no: 58, type: 3 },
  { img: require('@/assets/images/bg59.png'), color: '#ffffff', no: 59, type: 3 },
  { img: require('@/assets/images/bg60.png'), color: '#ffffff', no: 60, type: 3 },
  { img: require('@/assets/images/bg61.png'), color: '#ffffff', no: 61, type: 3 },

  { img: require('@/assets/images/bg62.png'), color: '#ffffff', no: 62, type: 4 },
  { img: require('@/assets/images/bg63.png'), color: '#ffffff', no: 63, type: 4 },
  { img: require('@/assets/images/bg64.png'), color: '#ffffff', no: 64, type: 4 },
  { img: require('@/assets/images/bg65.png'), color: '#1f2329', no: 65, type: 4 },
  { img: require('@/assets/images/bg66.png'), color: '#ffffff', no: 66, type: 4 },
  { img: require('@/assets/images/bg67.png'), color: '#1f2329', no: 67, type: 4 },
  { img: require('@/assets/images/bg68.png'), color: '#1f2329', no: 68, type: 4 },
  { img: require('@/assets/images/bg69.png'), color: '#ffffff', no: 69, type: 4 },
  { img: require('@/assets/images/bg70.png'), color: '#1f2329', no: 70, type: 4 },
  { img: require('@/assets/images/bg71.png'), color: '#1f2329', no: 71, type: 4 },
  { img: require('@/assets/images/bg72.png'), color: '#1f2329', no: 72, type: 4 },
  { img: require('@/assets/images/bg73.png'), color: '#1f2329', no: 73, type: 4 },
  { img: require('@/assets/images/bg74.png'), color: '#ffffff', no: 74, type: 4 },
  { img: require('@/assets/images/bg75.png'), color: '#ffffff', no: 75, type: 4 },
]