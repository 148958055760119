<template>
  <div class="app-container">
    <div class="top-panel">
      <div class="title">知识库</div>
      <div class="right-btn">
        <el-button v-if="isAdmin === 1" type="primary" size="small" style="margin-right: 10px;" @click="openConfig">配置知识库管理员</el-button>
        <el-popover placement="bottom" width="160" trigger="click" v-if="isAdmin === 2 || isAdmin === 1">
          <div class="right-panel">
            <ul class="operate-list">
              <!-- <li class="operate-item" @click="selectCatalog">
                <span class="icon">
                  <svg-icon icon-class="file"></svg-icon>
                </span>
                <span class="operate">新建文件</span>
              </li>
              <li class="operate-item">
                <span class="icon">
                  <svg-icon icon-class="folder_on"></svg-icon>
                </span>
                <span class="operate">新建文件夹</span>
              </li> -->
              <li class="operate-item" v-if="isAdmin === 2 || isAdmin === 1" @click="addKnowledgeSpace">
                <span class="icon">
                  <svg-icon icon-class="folder_on"></svg-icon>
                </span>
                <span class="operate">新建知识空间</span>
              </li>
            </ul>
          </div>
          <el-button slot="reference" type="primary" size="small">新建<i class="el-icon-arrow-down el-icon--right"></i></el-button>
        </el-popover>
      </div>
    </div>
    <div class="search-panel">
      <span class="search-type">全部空间</span>
      <div class="search-text">
        <el-input class="search-user" v-model="searchText" placeholder="请输入内容" size="mini" clearable :maxlength="100" @input="changeInput"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
      </div>
    </div>
    <ul class="main-panel clearfix" v-show="knowledgeList.length">
      <li class="item-panel" v-for="(item, index) in knowledgeList" :key="item.id" @click="jumpDetail(item)" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)">
        <img :src="item.img" alt="">
        <svg-icon class="item-panel-icon" :icon-class="item.collection ? 'collection' : 'notNollected'" @click.stop="changeCollection(item, index)" />
        <div class="item-panel-text">
          <h3 :style="{'color': item.color}">{{item.name}}</h3>
          <span :style="{'color': item.color}">{{item.remark}}</span>
        </div>
        <div v-if="item.isShow && (isAdmin || item.isAdmin)" class="item-panel-box" @click.stop="knowledgeSpaceSet(item)">
          <svg-icon icon-class="setting" class="setting-svg" />
          <span>知识空间设置</span>
        </div>
      </li>
    </ul>
    <div class="main-panel-nodata" v-show="knowledgeList.length===0 && searchText">
      <img src="//lf-scm-cn.feishucdn.com/ccm/pc/web/resource/bear/images/illustration_empty-neutral-search-failed.a70a4602.svg" alt="">
      <p>无匹配的知识空间</p>
    </div>
    <!-- 选择章节目录 -->
    <catalog-select :visible="visible" :departId="departId" @closeTab="closeTab"></catalog-select>
    <config-dialog :dialogFormVisible="dialogFormVisible" @closeDialog="closeDialog" />
    <knowledgeSpace ref="knowledgeSpace" @getList="getKnowledgeList" />
    <knowledgeSpaceSetting ref="knowledgeSpaceSetting" :isAdmin="isAdmin" @getList="getKnowledgeList" />
  </div>
</template>
<script>
import catalogSelect from '@/components/catalogSelect/index'
import ConfigDialog from './componentes/configDialog.vue'
import { getAdmin, getKnowledgeSpace } from '@/api/knowledgePermissions'
import knowledgeSpace from './componentes/knowledgeSpaceDialog'
import knowledgeSpaceSetting from './componentes/spaceSetting.vue'
import imagesList from './imageData.js'
export default {
  components: { catalogSelect, ConfigDialog, knowledgeSpace, knowledgeSpaceSetting },
  data() {
    return {
      searchText: '',
      visible: false,
      departId: null,
      dialogFormVisible: false,
      isAdmin: null,
      knowledgeList: [],
      isShow: false,
      list: [] // 完整数据
    }
  },
  created() {
    getAdmin({ workcode: this.$store.getters.workcode }).then(res => {
      this.isAdmin = res.data.length > 0 ? res.data[0].is_admin : null
    }).catch(error => console.log(error))
    this.getKnowledgeList()
  },
  methods: {
    getKnowledgeList() {
      const params = {
        departmentId: this.$store.getters.departmentId ? this.$store.getters.departmentId : '',
        workcode: this.$store.getters.workcode,
        udId: this.$store.getters.udId
      }
      getKnowledgeSpace(params).then(res => {
        this.knowledgeList = res.data && res.data.length > 0 ? res.data : []
        this.knowledgeList.forEach(item => {
          item.img = imagesList.filter(img => img.no === item.bgNo)[0].img || 1
          item.color = imagesList.filter(img => img.no === item.bgNo)[0].color || '#ffffff'
          this.$set(item, 'collection', false)
          this.$set(item, 'isShow', false)
        })
        this.list = JSON.parse(JSON.stringify(this.knowledgeList))
      }).catch(error => { console.log(error) })
    },
    selectCatalog() {
      this.visible = true
    },
    closeTab() {
      this.visible = false
    },
    jumpDetail(item) {
      // if (item.permission === 1) { // 只读
      //   console.log('只读', item.permission)
      // } else if (item.permission === 2) { // 读写
      //   console.log('读写', item.permission)
      // }
      const aUrl = this.$router.resolve({ path: '/wiki/detail/', query: { id: item.id }})
      window.open(aUrl.href, '_blank')
    },
    openConfig() { // 超级管理员可见  配置知识库子管理员
      this.dialogFormVisible = true
    },
    closeDialog() {
      this.dialogFormVisible = false
    },
    addKnowledgeSpace() {
      this.$refs.knowledgeSpace.openDialog()
    },
    knowledgeSpaceSet(item) {
      this.$refs.knowledgeSpaceSetting.openDialog(item)
    },
    changeCollection(item, index) {
      item.collection = !item.collection
    },
    mouseenter(item) {
      item.isShow = true
    },
    mouseleave(item) {
      item.isShow = false
    },
    changeInput(val) {
      const arr = JSON.parse(JSON.stringify(this.list))
      if (this.searchText) {
        this.knowledgeList = arr.filter(item => {
          if (item.name) {
            return item.name.indexOf(val) > -1
          } else if (item.udName) {
            return item.udName.indexOf(val) > -1
          }
        })
      } else {
        this.knowledgeList = arr
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.top-panel {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  padding-bottom: 20px;
  .title {
    font-size: 18px;
    display: flex;
    color: #38383a;
    font-weight: bold;
  }
  .right-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 45px;
      margin-right: 10px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.search-panel {
  display: flex;
  height: 32px;
  line-height: 32px;
  margin-bottom: 12px;
  width: 100%;
  .search-type {
    font-size: 16px;
    color: #38383a;
  }
  .search-text {
    display: flex;
    margin-left: auto;
  }
}
.main-panel {
  list-style-type: none;
  border-top: 1px solid #d8dadb;
  padding: 30px 20px;
  overflow-y: auto;
  .item-panel {
    width: 140px;
    height: 196px;
    float: left;
    margin-right: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    position: relative;
    &-icon {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 2;
    }
    &-text {
      position: absolute;
      top: 10px;
      padding: 0 15px;
      width: 140px;
      h3 {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font-weight: 600;
        font-size: 16px;
      }
      span {
        width: 110px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: wrap;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &-box {
      width: 140px;
      height: 30px;
      background-color: rgba(0, 0, 0, .3);
      font-size: 12px;
      position: absolute;
      bottom: 8px;
      color: #ffffff;
      font-weight: 700;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      line-height: 30px;
      padding: 0 18px;
      animation: animate 1s ease; /*ease单次执行动画，animation绑定动画效果*/
      -moz-animation: animate 1s ease; /* Firefox */
      -webkit-animation: animate 1s ease; /* Safari and Chrome */
      -o-animation: animate 1s ease; /* Opera */
      .setting-svg {
        vertical-align: text-bottom;
        margin-right: 5px;
      }
      @keyframes animate{
        0% {
          bottom: 0px;
          opacity: 0;
        }
        100% {
          bottom: 8px;
          opacity: 1;
        }
      }
    }
    img {
      width: 140px;
      object-fit: cover;
      border-radius: 8px;
    }
    .department {
      display: inline-block;
      line-height: 50px;
      width: 100%;
      text-align: center;
    }
  }
}
.main-panel-nodata {
  margin: 100px auto 0;
  p {
    font-size: 14px;
    color: rgb(100, 106, 115);
    text-align: center;
  }
}
</style>
