<template>
  <el-dialog title="新建知识空间" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form :model="form" :rules="ruleForm" ref="ruleForm">
      <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="简介" :label-width="formLabelWidth" prop="remark">
        <el-input type="texteara" v-model="form.remark" autocomplete="off" resize="none"></el-input>
      </el-form-item>
      <el-form-item label="可见范围" :label-width="formLabelWidth" prop="isToAll">
        <el-radio-group v-model="form.isToAll">
          <el-radio :label="0">仅知识空间成员可见</el-radio>
          <el-radio :label="1">所有人公开可见</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </div>
  </el-dialog>
</template>

<script>
import { addKnowledgeSpace } from '@/api/knowledgePermissions'

export default {
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: '100px',
      form: {
        name: '',
        remark: '',
        isToAll: 1
      },
      ruleForm: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        isToAll: [{ required: true, message: '请选择可见范围', trigger: 'change' }]
      }
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.ruleForm.resetFields()
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            createUser: this.$store.getters.name,
            createCode: this.$store.getters.workcode
          }
          addKnowledgeSpace(params).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.message)
              this.handleClose()
              this.$emit('getList')
            } else {
              this.$message.error(res.message)
            }
          }).catch(error => console.log(error))
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
