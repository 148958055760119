<template>
  <!-- 选择人员，查询选择，或根据部门批量选择 -->
  <div v-if="visible">
    <el-dialog :visible="true" :append-to-body="true" title="选择人员" :close-on-click-modal="false" :before-close="closeTab" width="1000px" v-loading="loading">
      <div class="container">
        <div class="container-left">
          <el-input placeholder="搜索用户或部门" clearable v-model="filterText" />
          <el-tree
            class="role_list"
            ref="tree"
            :data="formatDepartmentList"
            show-checkbox
            node-key="nodeKey"
            :props="defaultProps"
            :check-strictly="false"
            :filter-node-method="filterNode"
            :default-checked-keys="nodeKeyArr"
            @check-change="handleCheckChange"
            @node-expand="handleExpand"
          >
            <template slot-scope="{ data }">
              <span>{{ data.udName }} <span v-if="data.is_user && data.on_job === 0" style="color:red">[已离职]</span></span>
            </template>
          </el-tree>
        </div>
        <div class="container-right">
          <div class="container-right-checked">已选：{{filterSelectList.length}}</div>
          <ul>
            <li v-for="(item, index) in filterSelectList" :key="index">
              <div v-if="item.name">{{item.name && item.name.length > 2 ? item.name.substring(1, item.name.length) : item.name}}</div>
              <img v-if="!item.name" src="../../../public/static/img/organization.png" alt="">
              <span>{{item.name ? item.name: item.udName}}</span>
              <i class="el-icon-close" @click="deleteList(item)" />
            </li>
          </ul>
        </div>
      </div>
      <div slot="footer" style="margin-top:2em">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDepartTree } from '@/api/user'

export default {
  name: 'departmentTree',
  data() {
    return {
      filterText: '',
      formatDepartmentList: [],
      defaultProps: {
        children: 'children',
        label: 'udName'
      },
      departmentList: [],
      userList: [],
      checkedList: [],
      loading: false,
      nodeKeyArr: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    user: {
      type: String,
      default: () => '1'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    allUser: function() {
      return this.$store.getters.allUsers
    },
    filterSelectList: function() {
      return this.handleData(this.checkedList)
    }
  },
  mounted() {
    getDepartTree().then(res => {
      this.departmentList = res.data
    })
  },
  watch: {
    filterText(val) {
      if (this.$refs.tree) {
        this.$refs.tree.filter(val)
      }
    },
    visible(val) {
      if (val) {
        this.filterText = ''
        this.checkedList = JSON.parse(JSON.stringify(this.list))
        if (this.$store.getters.allUsers.length === 0) {
          this.loading = true
          this.$store.dispatch('GetUsers')
        } else {
          if (this.checkedList.length > 0) {
            this.defaultChecked(this.checkedList, true)
          } else {
            this.defaultChecked(this.checkedList, false)
          }
        }
      } else {
        this.checkedList = []
      }
    },
    allUser: {
      handler(newVal, oldVal) {
        if (oldVal.length <= 0 && newVal.length > 0) {
          this.userList = newVal
          this.formatList(this.departmentList, this.userList)
        }
      },
      deep: true
      // immediate: true
    }
  },
  methods: {
    closeTab() {
      const arr = JSON.parse(JSON.stringify(this.filterSelectList)).map(item => ({ ...item, permission: Number(this.user) }))
      this.$emit('submit', arr, false)
      this.$emit('closeTree', false)
    },
    save() {
      this.closeTab()
      const arr = JSON.parse(JSON.stringify(this.filterSelectList)).map(item => ({ ...item, permission: Number(this.user) }))
      this.$emit('submit', arr, true)
    },
    defaultChecked(check, flag) {
      const _this = this
      if (flag) {
        if (check && check.length > 0) {
          check.forEach(ele => {
            clearList(_this.formatDepartmentList, ele, flag)
          })
        }
      } else {
        clearList(_this.formatDepartmentList, {}, flag)
      }
      function clearList(data, ele, flag) {
        data.forEach(item => {
          if (item.children && item.children.length > 0) {
            if (flag) {
              if (item.departmentId === ele.departmentId && item.workcode === ele.workcode) {
                _this.$set(item, 'disabled', flag)
                _this.nodeKeyArr.push(item.nodeKey)
              }
              clearList(item.children, ele, flag)
            } else {
              _this.$set(item, 'disabled', flag)
              _this.nodeKeyArr = []
              clearList(item.children, {}, flag)
            }
          } else {
            if (flag) {
              if (item.departmentId === ele.departmentId && item.workcode === ele.workcode) {
                _this.$set(item, 'disabled', flag)
                _this.nodeKeyArr.push(item.nodeKey)
              }
              return item
            } else {
              _this.$set(item, 'disabled', flag)
              _this.nodeKeyArr = []
            }
          }
        })
      }
    },
    deleteList(val) {
      const _this = this
      const index = this.checkedList.findIndex(item => (item.departmentId === val.departmentId && item.workcode === val.workcode) || (item.nodeKey ? item.nodeKey === val.nodeKey : false))
      if (index > -1) {
        this.checkedList.splice(index, 1)
        clearList(this.formatDepartmentList)
      }
      function clearList(data) {
        data.forEach(item => {
          if ((item.departmentId === val.departmentId && item.workcode === val.workcode) || (item.nodeKey ? item.nodeKey === val.nodeKey : false)) {
            if (item.nodeKey) {
              _this.$refs.tree.setChecked(item.nodeKey, false)
            } else {
              _this.$refs.tree.setChecked(item.departmentId, false)
            }
            _this.$set(item, 'disabled', false)
          }
          if (item.children && item.children.length > 0) {
            clearList(item.children)
          } else {
            return item
          }
        })
      }
    },
    handleCheckChange(data, checked, children) {
      if (checked) {
        this.checkedList.push(data)
      } else {
        const index = this.checkedList.findIndex(item => item.nodeKey === data.nodeKey)
        if (index > -1) this.checkedList.splice(index, 1)
      }
    },
    formatList(departmentList, userList) {
      const _this = this
      find(departmentList)
      function find(departmentList) {
        departmentList.forEach((item, index) => {
          if (item.children && item.children.length > 0) {
            _this.$set(item, 'departmentId', item.udId)
            _this.$set(item, 'workcode', item.workcode ? item.workcode : '')
            _this.$set(item, 'nodeKey', `${item.udId}-${index}`)
            find(item.children)
            item = setUser(item, userList)
          } else {
            _this.$set(item, 'departmentId', item.udId)
            _this.$set(item, 'workcode', item.workcode ? item.workcode : '')
            _this.$set(item, 'nodeKey', `${item.udId}-${index}`)
            item = setUser(item, userList)
            return item
          }
        })
        return departmentList
      }
      function setUser(data, userList) {
        userList.forEach((ele, i) => {
          _this.$set(ele, 'children', null)
          _this.$set(ele, 'workcode', ele.username ? ele.username : '')
          _this.$set(ele, 'nodeKey', `${ele.departmentId}-${ele.workcode}-${i}`)
          ele.udName = ele.name + '[' + ele.username + ']'
          // if (ele.department_id === data.udId && ele.on_job === 1) {
          if (ele.departmentId === data.udId) {
            if (!Array.isArray(data.children)) {
              _this.$set(data, 'children', [])
            }
            data.children.push(ele)
          }
        })
        return data
      }
      this.formatDepartmentList = departmentList
      if (this.checkedList.length > 0) {
        this.defaultChecked(this.checkedList, true)
      } else {
        this.defaultChecked(this.checkedList, false)
      }
      this.loading = false
    },
    filterNode(value, data) {
      if (!value) return true
      return data.udName.indexOf(value) !== -1
    },
    handleExpand(data, node, components) {
      if (data.disabled || node.checked) {
        node.expanded = false
      }
    },
    // 处理合并数据（选了组则组下的人不显示，选了部门则其下组不显示），优先处理人
    handleData(data) {
      const user_arr = data.filter(i => i.name)
      const depart_arr = data.filter(i => i.longId)
      const user_arr_filter = []
      const depart_arr_filter = []
      // 处理人员
      user_arr.forEach(i => {
        if (depart_arr.filter(j => j.udId === i.departmentId).length === 0) {
          user_arr_filter.push(i)
        }
      })
      // 处理部门
      // 部门的父级存在的条件，longId（除去最后一位：本身id）包含在depart_arr中的departmentId
      depart_arr.forEach(i => {
        const ids = i.longId.split('-')
        const parentIds = ids.slice(0, ids.length - 1)
        const hasParent = depart_arr.filter(j => {
          return parentIds.indexOf(j.departmentId.toString()) > -1
        })
        if (hasParent.length === 0) {
          depart_arr_filter.push(i)
        }
      })
      console.log('user', user_arr_filter)
      console.log('depart', depart_arr_filter)
      console.log(depart_arr_filter.concat(user_arr_filter))
      return depart_arr_filter.concat(user_arr_filter)
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  display: flex;
  min-height: 500px;
  &-left {
    flex: 1;
    border: 1px solid #ededed;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 20px;
    .el-input {
      margin-bottom: 10px;
    }
  }
  &-right {
    flex: 1;
    border: 1px solid #ededed;
    border-left: none;
    padding: 20px 0;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    &-checked {
      height: 44px;
      line-height: 44px;
      padding-left: 20px;
    }
    ul {
      height: 430px;
      overflow: auto;
      list-style-type: none;
      margin: 0px;
      li {
        padding-left: 20px;
        display: flex;
        flex-wrap: nowrap;
        height: 44px;
        line-height: 44px;
        margin: 0 8px;
        &:hover {
          background-color: #1f232914;
          border-radius: 10px;
          cursor: pointer;
        }
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
          margin: 6px 15px 0 0;
        }
        div {
          width: 28px;
          height: 28px;
          background-color: #f4870a;
          border-radius: 50%;
          font-size: 12px;
          line-height: 28px;
          text-align: center;
          color: #ffffff;
          margin: 9px 15px 0px 0;
        }
        span {
          display: inline-block;
          width: 78%;
        }
        i {
          line-height: 28px;
          width: 28px;
          text-align: center;
          height: 28px;
          margin-top: 9px;
          &:hover {
            background-color: #1f23291a;
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
