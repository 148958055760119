<template>
  <el-dialog title="选择知识库管理员" width="30%" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="close">
    <el-form :model="form" ref="form">
      <el-form-item>
        <el-select v-model="form.userList" filterable multiple placeholder="请选择" @change="change">
          <el-option
            v-for="(item,index) in $store.state.user.workcodeList"
            :key="index"
            :label="(item.name ? item.name: item.username) + '['+item.workcode+']'"
            :value="item.workcode">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addAdmin, getSubAdmin } from '@/api/knowledgePermissions'
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      form: {
        userList: []
      }
    }
  },
  watch: {
    dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.form.userList = []
      } else {
        getSubAdmin({ is_admin: 2 }).then(res => {
          if (res.code === '000000') {
            this.form.userList = []
            if (res.data.length > 0) {
              res.data.forEach(item => {
                this.form.userList.push(item.workcode)
              })
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    }
  },
  mounted() {
    if (this.$store.state.user.workcodeList.length === 0) {
      this.$store.dispatch('GetWorkcode')
    }
  },
  methods: {
    change(val) {
      console.log(val)
    },
    close() {
      this.$emit('closeDialog')
    },
    submit() {
      // if (this.form.userList.length > 0) {
      const arr = []
      this.form.userList.forEach(item => {
        const obj = this.$store.state.user.workcodeList.find(ele => ele.workcode === item)
        if (obj) {
          arr.push({
            username: obj.name,
            workcode: obj.workcode,
            is_admin: 2
          })
        }
      })
      addAdmin(arr).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      }).catch(error => console.log(error))
      // } else {
      //   this.$message.warning('未选择用户，无法提交！')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
